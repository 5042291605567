import React from 'react'
import { Container } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import * as styles from './banner.module.scss'


export default (props) => {


  let headings = <div><h1>{props.title}</h1><hr /><h3>{props.subtitle}</h3></div>;

  if(props.subtitleFirst) {
    headings = <div><h3>{props.subtitle}</h3><h1>{props.title}</h1></div>;
  }

  //console.log(props.backgroundImage);
  if(props.backgroundImage !== undefined ) {
    const imageData = props.backgroundImage.localFile.childImageSharp.fluid

    const backgroundFluidImageStack = [
      imageData,
      `linear-gradient(0deg,rgba(0,0,0,.1),rgba(0,0,0,.5))`,
    ].reverse()

    return(
      <div>
        
          { props.backgroundVideoUrl ? 
          <div className={`d-none d-lg-flex vw-100  ${styles.banner}`}>
            
              <video autoPlay muted loop>
                <source src={props.backgroundVideoUrl } type="video/mp4" />
              </video>
       
            <div className={styles.videoGrad}>&nbsp;</div>
            <Container className={"m-auto col-lg-8 d-flex flex-column text-center "+styles.bannerContent} style={{zIndex: '100', position: 'relative'}}>
              <div>{headings}</div>
              <div dangerouslySetInnerHTML={{__html: props.bodytext}} />
              { props.children }
            </Container>
          </div>
          : 
          <BackgroundImage
            Tag="div"
            className={`d-none  d-lg-flex w-100 ${styles.banner}`}
            fluid={backgroundFluidImageStack}
            fadeIn={true}
            keepStatic={true}
          >
            <Container className={"m-auto col-lg-8 d-flex flex-column text-center "+styles.bannerContent} style={{zIndex: '100', position: 'relative'}}>
              <div>{headings}</div>
              <div dangerouslySetInnerHTML={{__html: props.bodytext}} />
              { props.children }
            </Container>
          </BackgroundImage>
          }
          
          
        <BackgroundImage
          Tag="div"
          className={`d-lg-none ${styles.banner}`}
          fluid={backgroundFluidImageStack}
          fadeIn={true}
          keepStatic={true}
        >
          <Container className={"m-auto col-lg-8 d-flex flex-column text-center "+styles.bannerContent} style={{zIndex: '100'}}>
            <div>{headings}</div>
            <div className="col-12 col-lg-10 m-auto" dangerouslySetInnerHTML={{__html: props.bodytext}} />
            { props.children }
          </Container>
        </BackgroundImage>
      </div>
    )
  } else {
    return(
      <div className={styles.banner}>
        <Container className={"m-auto col-8 d-flex flex-column text-center "+styles.bannerContent}>
          <div>{headings}</div>
          <div dangerouslySetInnerHTML={{__html: props.bodytext}} />
          { props.children }
        </Container>
      </div>

    )
  }

}
