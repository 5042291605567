import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import logo from '../images/logo.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import * as styles from './footer.module.scss'



export default (props) => {

  return(
    <footer>
      <Container className="w-md-75 pl-3">
        <Row>
          <Col lg={3}>
            <img src={logo} className={styles.logo} alt="The Connor Group" />
            <p className={styles.companyAddress}>10510 N Springboro Pike,<br />Dayton, OH 45342</p>
            <ul className={styles.social}>
              <li>
                <a rel="noreferrer" target="_blank" name="Facebook" href="https://www.facebook.com/theconnorgrp/"><FontAwesomeIcon icon={faFacebookF} /></a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" name="LinkedIn" href="https://www.linkedin.com/company/the-connor-group"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" name="Youtube" href="https://www.youtube.com/channel/UCT4-eW2SlatTwpms391Lvxg"><FontAwesomeIcon icon={faYoutube} /></a>
              </li>
            </ul>
          </Col>
          <Col lg={3} className="d-lg-flex flex-column pl-lg-5">
            <h2>Our Company</h2>
            <ul>
              <li>
                <a rel="noopener" href="https://connorgroup.com/company/">About Us</a>
              </li>
              <li>
                <a rel="noopener" href="https://connorgroup.com/news/">Company News</a>
              </li>
              <li>
                <a rel="noopener" href="https://connorgroup.com/kids-and-community/">Giving Back</a>
              </li>
              <li>
                <Link to="/">Careers</Link>
              </li>
              <li>
                <a rel="noopener" href="https://connorgroup.com/blog/">The Lifestyle Blog</a>
              </li>
              <li>
                <a rel="noopener" href="https://tcgcareersdev.wpengine.com/wp-content/uploads/2022/07/The-Connor-Group-Biometric-Data-Release-071822.pdf">Biometric Data Policy</a>
              </li>
            </ul>
          </Col>
          <Col lg={3} className="d-lg-flex flex-column">
            <h2>Positions</h2>
            <ul>
              <li><Link to="/property-managers">Property Managers</Link></li>
              <li><Link to="/sales-associates">Sales Associates</Link></li>
              <li><Link to="/maintenance">Maintenance</Link></li>
              <li><Link to="/central-support-office">Central Support Office</Link></li>
            </ul>
          </Col>
          <Col lg={3} className="d-lg-flex flex-column">
            <h2>Start Your Career</h2>
            <div className={styles.footerBtn}><a href="/current-openings">Current Openings &nbsp;<span className={styles.carrot}><FontAwesomeIcon icon={faChevronRight} /></span></a></div>
          </Col>
        </Row>
        <Row className={styles.copy}>
          <Col className="p-3 text-center">
            <p>&copy;{(new Date().getFullYear())} The Connor Group All Rights Reserved - <span className="d-none d-md-inline-block"><a href="https://connorgroup.com/privacy-policy/">Privacy Policy</a></span></p>
            <div className="d-md-none"><a rel="noopener" href="https://connorgroup.com/privacy-policy/">Privacy Policy</a></div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
