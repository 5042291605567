import React, { useState, useLayoutEffect } from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav, Dropdown, NavItem, NavLink, Container, Row, Col, Accordion, Button } from 'react-bootstrap'
import logo from '../images/logo.svg'
import hamburger from '../images/connorHamburger.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import "../styles/global.scss";
import * as styles from './header.module.scss'


export default (props) => {
  const [mobileMenuState, setMobileMenuState] = useState("open");

  function mobileDrawer() {
    if(mobileMenuState === "closed"){
      setMobileMenuState("open")
    } else {
      setMobileMenuState("closed")
    }
    //console.log(mobileMenuState);
  }


  return(
    <Navbar className={styles.header} expand="md">
      <Container>
        <Row className="w-100">
          <Col xs={9} md={5} lg={4} className="d-flex align-items-center">
            <Nav.Item className="sr-only">
              <a href="#content">Skip Navigation</a>
            </Nav.Item>
            <Nav.Item>
               <Nav.Link href="/">
                <img src={logo} className="w-100" width="100%" alt="The Connor Group" />
               </Nav.Link>
            </Nav.Item>
          </Col>
          <Col className="d-md-none d-flex justify-content-center align-items-center" xs={3}>
            <Navbar.Toggle className={styles.hamburger} aria-controls="responsive-navbar-nav" onClick={() => mobileDrawer()}>
              {mobileMenuState === "closed"
                ? <FontAwesomeIcon className={styles.closeIcon} icon={faTimes} />
                : <img src={hamburger} alt="menu" />
              }
            </Navbar.Toggle>
          </Col>
          <Col xs={3} md={7} lg={8} className="align-items-center d-none d-md-flex">
            <Navbar.Collapse className={styles.topNav}>
              <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} className={styles.topNavLink}>Positions</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/leadership" className={styles.dropdownItem}>Leadership</Dropdown.Item>
                      <Dropdown.Item href="/property-managers" className={styles.dropdownItem}>Property Managers</Dropdown.Item>
                      <Dropdown.Item href="/sales-associates" className={styles.dropdownItem}>Sales Associates</Dropdown.Item>
                      <Dropdown.Item href="/maintenance" className={styles.dropdownItem}>Maintenance</Dropdown.Item>
                      <Dropdown.Item href="/central-support-office" className={styles.dropdownItem}>Central Support Office</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
              <Nav.Link href="/current-openings" className={styles.topNavLink}>Openings</Nav.Link>
              <Nav.Link href="/continued-growth/" className={styles.topNavLink}>Growth</Nav.Link>
              <Nav.Link href="/reward-and-recognition/" className={styles.topNavLink}>Reward &amp; Recognition</Nav.Link>
              <Nav.Link href="/benefits/" className={styles.topNavLink}>Benefits</Nav.Link>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
      <Navbar.Collapse id="responsive-navbar-nav" className={styles.mobileMenu}>
        <div className="w-100">
          <Container style={{ paddingTop: `5rem` }}>
              <Accordion defaultActiveKey="1" className={styles.mobileDrop}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Positions
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"0"}>
                <ul>
                  <li><Link to="/property-managers">Property Managers</Link></li>
                  <li><Link to="/sales-associates">Sales Associates</Link></li>
                  <li><Link to="/maintenance">Maintenance</Link></li>
                  <li><Link to="/central-support-office">Central Support Office</Link></li>
                </ul>
                </Accordion.Collapse>
                </Accordion>
                <Nav.Link href="/current-openings" className={styles.mobileLink}>Openings</Nav.Link>
                <Nav.Link href="/continued-growth/" className={styles.mobileLink}>Growth</Nav.Link>
                <Nav.Link href="/reward-and-recognition/" className={styles.mobileLink}>Reward &amp; Recognition</Nav.Link>
                <Nav.Link href="/benefits/" className={styles.mobileLink}>Benefits</Nav.Link>
          </Container>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}
